import Dashboard from "layouts/dashboard";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";
import Game from "layouts/game/game";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import StorageIcon from "@mui/icons-material/Storage";
import Resource from "layouts/resource";
import CasinoIcon from "@mui/icons-material/Casino";
import PersonIcon from "@mui/icons-material/Person";

// @mui icons
import Icon from "@mui/material/Icon";
import ResourceView from "layouts/resourceView";
import CardManagement from "layouts/card";
import UserManager from "layouts/users";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "Game",
    key: "game",
    icon: <SportsEsportsIcon fontSize="small" />,
    route: "/game",
    component: <Game />,
  },
  {
    type: "collapse",
    name: "Resource",
    key: "resource",
    icon: <StorageIcon fontSize="small" />,
    route: "/resource",
    component: <Resource />,
  },
  {
    type: "collapse",
    name: "ResourceView",
    key: "resource_view",
    icon: <StorageIcon fontSize="small" />,
    route: "/resource/:resourceId/view",
    component: <ResourceView />,
    hideNav: true,
  },
  {
    type: "collapse",
    name: "Card",
    key: "card",
    icon: <CasinoIcon fontSize="small" />,
    route: "/card",
    component: <CardManagement />,
  },
  {
    type: "collapse",
    name: "Users",
    key: "users",
    icon: <PersonIcon fontSize="small" />,
    route: "/users",
    component: <UserManager />,
  },
  {
    type: "collapse",
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/sign-in",
    component: <SignIn />,
    hideNav: true,
  },
  {
    type: "collapse",
    name: "Sign Up",
    key: "sign-up",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/authentication/sign-up",
    component: <SignUp />,
    hideNav: true,
  },
];

export default routes;
