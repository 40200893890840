import axios from "axios";
import { useLoading } from "context/LoadingContext";
import localforage from "localforage";

export function request(url, method, data, setLoading = null, no_token = false, fullUrl = null) {
  return new Promise((resolve, reject) => {
    if (setLoading) setLoading(true);

    localforage.getItem("jwt").then((user_token) => {
      if (!user_token && url !== "/v1/users/login")
        return (window.location = "/authentication/sign-in");
      const isFormData = data instanceof FormData;

      const requestOptions = {
        method: method || "GET",
        url: fullUrl ? url : process.env.REACT_APP_BE_URL + url,
        headers: {
          Authorization: !no_token && user_token ? `Bearer ${user_token}` : undefined,
          "Content-Type": isFormData ? "multipart/form-data" : "application/json",
        },
        data: isFormData ? data : check_data(data),
      };

      axios(requestOptions)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        })
        .finally(() => {
          if (setLoading) setLoading(false);
        });
    });
  });
}

export const check_data = (data) => {
  for (const key in data) {
    if (data[key] === undefined) data[key] = "";
  }
  return JSON.stringify(data);
};
