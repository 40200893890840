import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";
import MDButton from "components/MDButton";
import Autocomplete from "@mui/material/Autocomplete";
import { request } from "utils/utils";
import { useLoading } from "context/LoadingContext";

function CardDialog({ open, onClose, onSuccess, card, isEdit, resourceId, showNotification }) {
  const { setLoading } = useLoading();
  const [selectedCard, setSelectedCard] = useState(
    card || {
      name: "",
      description: "",
      resource_needed: 0,
      level_id: 0,
      profit_gain_plus: 0,
      duration: 0,
      min_friend: 0,
      icon: "",
      requirement: { resources: [], req_coin: 0 },
    }
  );
  const [selectedFile, setSelectedFile] = useState(null);

  const [resourceOptions, setResourceOptions] = useState([]);
  const [selectedResources, setSelectedResources] = useState([]);
  const [reqCoin, setReqCoin] = useState(selectedCard?.requirement?.req_coin || 0);

  const handleInputChange = (e) => {
    setSelectedCard({ ...selectedCard, [e.target.name]: e.target.value });
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  useEffect(() => {
    const fetchResourceOptions = async () => {
      try {
        const response = await request("v1/resource", "GET", null, setLoading);
        setResourceOptions(response.data || []);
      } catch (error) {
        console.error("Error fetching resource options:", error);
        showNotification("error", "Failed to load resources.");
      }
    };

    fetchResourceOptions();
  }, []);

  useEffect(() => {
    setSelectedCard(card);
    setReqCoin(card.requirement ? JSON.parse(card.requirement)?.req_coin : 0);
    setSelectedResources(card.requirement ? JSON.parse(card.requirement)?.resources : []);
  }, [card]);

  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append("name", selectedCard.name);
    formData.append("text_description", selectedCard.description);
    formData.append("resource_needed", selectedCard.resource_needed || 0);
    formData.append("level_id", selectedCard.level_id || 0);
    formData.append("profit_gain_plus", selectedCard.profit_gain_plus || 0);
    formData.append("duration", Number(selectedCard.duration) || 0);
    formData.append("min_friend", Number(selectedCard.min_friend) || 0);
    const filteredResources = selectedResources.filter((resource) => resource.resource_id);

    const requirementData = { resources: filteredResources, req_coin: reqCoin };
    formData.append("requirement", JSON.stringify(requirementData));

    if (selectedFile) {
      formData.append("icon", selectedFile);
    }

    try {
      const url = isEdit
        ? `v1/resource/update-card?cardId=${selectedCard.id}`
        : `v1/resource/create-card?resourceId=${resourceId}`;

      const res = await request(url, "POST", formData, setLoading);
      showNotification(res.status, res.message);

      onSuccess();
      onClose();
    } catch (error) {
      console.error("Error submitting card:", error);
      showNotification("error", "Failed to submit the card.");
    }
  };

  const addResourceRequirement = () => {
    setSelectedResources([...selectedResources, { resource_id: "", lvl: 1 }]);
  };

  const handleResourceChange = (index, field, value) => {
    const updatedResources = [...selectedResources];
    updatedResources[index][field] = value;
    setSelectedResources(updatedResources);
  };

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>{isEdit ? "Edit Card" : "Add New Card"}</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            name="name"
            label="Card Name"
            fullWidth
            value={selectedCard.name}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="description"
            label="Description"
            fullWidth
            value={selectedCard.description}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="resource_needed"
            label="Resource Needed"
            type="number"
            fullWidth
            value={selectedCard.resource_needed}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="level_id"
            label="Level ID"
            type="number"
            fullWidth
            value={selectedCard.level_id}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="profit_gain_plus"
            label="Profit Gain Plus"
            type="number"
            fullWidth
            value={selectedCard.profit_gain_plus}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="duration"
            label="Duration"
            type="number"
            fullWidth
            value={selectedCard.duration}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="min_friend"
            label="Minimum Friend"
            type="number"
            fullWidth
            value={selectedCard.min_friend}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="icon"
            label="Icon"
            type="file"
            fullWidth
            onChange={handleFileChange}
          />

          {/* Requirement Field */}
          <DialogTitle>Requirement</DialogTitle>
          {selectedResources.map((resource, index) => (
            <div key={index} style={{ display: "flex", gap: "10px", marginBottom: "10px" }}>
              <Autocomplete
                options={resourceOptions}
                getOptionLabel={(option) => `Resource: ${option.name}`}
                value={resourceOptions.find((opt) => opt.id === resource.resource_id) || null}
                onChange={(e, newValue) =>
                  handleResourceChange(index, "resource_id", newValue?.id || "")
                }
                renderInput={(params) => <TextField {...params} label="Resource" />}
                isOptionEqualToValue={(option, value) => option.id === value}
                style={{ width: "50%" }}
              />
              <TextField
                label="Level"
                type="number"
                value={resource.lvl}
                onChange={(e) => handleResourceChange(index, "lvl", e.target.value)}
                style={{ width: "50%" }}
              />
            </div>
          ))}
          <MDButton
            onClick={addResourceRequirement}
            color="primary"
            style={{ marginBottom: "10px" }}
          >
            Add Resource
          </MDButton>
          <TextField
            margin="dense"
            label="Required Coin"
            type="number"
            fullWidth
            value={reqCoin}
            onChange={(e) => setReqCoin(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <MDButton onClick={onClose} color="error">
            Cancel
          </MDButton>
          <MDButton onClick={handleSubmit} color="success">
            {isEdit ? "Update" : "Create"}
          </MDButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

CardDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  card: PropTypes.object,
  isEdit: PropTypes.bool,
  resourceId: PropTypes.string.isRequired,
  showNotification: PropTypes.func.isRequired,
};

export default CardDialog;
