import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import axios from "axios";
import { useNavigate } from "react-router-dom";

// orbitex Dashboard React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

import useNotification from "services/NotificationService";

// orbitex Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import { request } from "utils/utils";

function UserManager() {
  const { showNotification, renderNotification } = useNotification();
  const [users, setUsers] = useState([]);
  const [columns, setColumns] = useState([]);
  const [open, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedUser, setSelectedUser] = useState({
    id: null,
    name: "",
    email: "",
    role: "",
  });
  const [selectedFile, setSelectedFile] = useState(null);

  const fetchUsers = async () => {
    try {
      console.log("HERE");
      const response = await request(`/v1/users`);

      console.log(response);
      setUsers(response.data.data);
      prepareColumns();
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const navigate = useNavigate();

  const handleView = (id) => {
    navigate(`/user/${id}/view`);
  };

  const prepareColumns = () => {
    setColumns([
      { Header: "ID", accessor: "id", align: "left" },
      { Header: "Name", accessor: "name", align: "left" },
      { Header: "Email", accessor: "email", align: "left" },
      { Header: "Role", accessor: "role", align: "left" },
      {
        Header: "Actions",
        accessor: "actions",
        align: "center",
        Cell: ({ row }) => (
          <>
            <MDButton variant="contained" color="info" onClick={() => handleOpen(row.original)}>
              Edit
            </MDButton>
            <MDButton
              variant="contained"
              color="error"
              onClick={() => handleDelete(row.original.id)}
              style={{ marginLeft: 10 }}
            >
              Delete
            </MDButton>
            <MDButton
              variant="contained"
              color="primary"
              onClick={() => handleView(row.original.id)} // دکمه View
              style={{ marginLeft: 10 }}
            >
              View
            </MDButton>
          </>
        ),
      },
    ]);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${process.env.REACT_APP_BE_URL}v1/users/${id}`);
      fetchUsers(); // Refresh user list after deletion
      showNotification("success", "check", "Success", "User deleted successfully!");
    } catch (error) {
      console.error("Error deleting user:", error);
      showNotification("error", "warning", "Error", "Failed to delete the user.");
    }
  };

  // Open the dialog for creating or editing a user
  const handleOpen = (
    user = {
      id: null,
      name: "",
      email: "",
      role: "",
    }
  ) => {
    setSelectedUser(user);
    setIsEdit(!!user.id);
    setOpen(true);
  };

  // Handle form input changes
  const handleInputChange = (e) => {
    setSelectedUser({ ...selectedUser, [e.target.name]: e.target.value });
  };

  // Handle file input changes
  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]); // Store the selected file (optional, if user has an avatar or profile picture)
  };

  // Handle form submission (Create or Edit user)
  const handleSubmit = async () => {
    const formData = new FormData();

    if (selectedUser.name) formData.append("name", selectedUser.name);
    if (selectedUser.email) formData.append("email", selectedUser.email);
    if (selectedUser.role) formData.append("role", selectedUser.role);

    if (selectedFile) {
      formData.append("avatar", selectedFile); // Optionally uploading a profile picture
    }

    try {
      if (isEdit) {
        await axios.post(
          `${process.env.REACT_APP_BE_URL}v1/users/edit?id=${selectedUser.id}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        showNotification("success", "check", "Success", "User updated successfully!");
      } else {
        await axios.post(`${process.env.REACT_APP_BE_URL}v1/users`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        showNotification("success", "check", "Success", "User created successfully!");
      }
      setOpen(false);
      fetchUsers();
    } catch (error) {
      console.error("Error submitting user:", error);
      showNotification("error", "warning", "Error", "Failed to submit the user.");
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <MDBox mb={3}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={2}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                  display="flex"
                  justifyContent="space-between"
                >
                  <MDTypography variant="h6" color="white">
                    Manage Users
                  </MDTypography>
                  <MDButton variant="contained" color="primary" onClick={() => handleOpen()}>
                    Add New User
                  </MDButton>
                </MDBox>
                <MDBox pt={3}>
                  <DataTable
                    table={{
                      columns: columns,
                      rows: users?.map((user) => ({
                        ...user,
                        actions: "",
                      })),
                    }}
                    isSorted={false}
                    entriesPerPage={5}
                    showTotalEntries={false}
                    noEndBorder
                  />
                </MDBox>
              </Card>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
      {/* Dialog for Create and Edit User */}
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>{isEdit ? "Edit User" : "Add New User"}</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            name="name"
            label="User Name"
            type="text"
            fullWidth
            value={selectedUser.name}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="email"
            label="Email"
            type="email"
            fullWidth
            value={selectedUser.email}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="role"
            label="Role"
            type="text"
            fullWidth
            value={selectedUser.role}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="avatar"
            label="Avatar"
            type="file"
            fullWidth
            onChange={handleFileChange}
          />
        </DialogContent>
        <DialogActions>
          <MDButton onClick={() => setOpen(false)} color="error">
            Cancel
          </MDButton>
          <MDButton onClick={handleSubmit} color="success">
            {isEdit ? "Update" : "Create"}
          </MDButton>
        </DialogActions>
      </Dialog>
      {renderNotification} {/* Render Notification */}
    </DashboardLayout>
  );
}

UserManager.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      role: PropTypes.string.isRequired,
    }),
  }),
};

export default UserManager;
