import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import axios from "axios";

// orbitex Dashboard React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

import useNotification from "services/NotificationService";

// orbitex Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import PropTypes from "prop-types";
import { request } from "utils/utils";
import { useLoading } from "context/LoadingContext";

function CardManagement() {
  const { showNotification, renderNotification } = useNotification();
  const [cards, setCards] = useState([]);
  const [columns, setColumns] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedCard, setSelectedCard] = useState({
    id: null,
    name: "",
    description: "",
    resource_needed: 0,
    level_id: 0,
    profit_gain_plus: 0,
    duration: 0,
    min_friend: 0,
    icon: "",
  });
  const [selectedFile, setSelectedFile] = useState(null);
  const { setLoading } = useLoading();

  const fetchCards = async () => {
    try {
      const response = await request(`v1/resource/get-cards`, "GET", null, setLoading);
      setCards(response.data);
      prepareColumns();
    } catch (error) {
      console.error("Error fetching cards:", error);
    }
  };

  useEffect(() => {
    fetchCards();
  }, []);

  const prepareColumns = () => {
    setColumns([
      { Header: "ID", accessor: "id", align: "left" },
      { Header: "Name", accessor: "name", align: "left" },
      { Header: "Resource Needed", accessor: "resource_needed", align: "left" },
      { Header: "Level ID", accessor: "level_id", align: "left" },
      { Header: "Profit Gain Plus", accessor: "profit_gain_plus", align: "left" },
      { Header: "Duration", accessor: "duration", align: "left" },
      { Header: "Min Friend", accessor: "min_friend", align: "left" },
      {
        Header: "Icon",
        accessor: "icon",
        align: "left",
        Cell: ({ row }) => <img width={50} height={50} src={row.original.icon} alt="icon" />,
      },
      {
        Header: "Actions",
        accessor: "actions",
        align: "center",
        Cell: ({ row }) => (
          <>
            <MDButton variant="contained" color="info" onClick={() => handleOpen(row.original)}>
              Edit
            </MDButton>
            <MDButton
              variant="contained"
              color="error"
              onClick={() => handleDelete(row.original.id)}
              style={{ marginLeft: 10 }}
            >
              Delete
            </MDButton>
          </>
        ),
      },
    ]);
  };

  const handleOpen = (
    card = {
      id: null,
      name: "",
      description: "",
      resource_needed: 0,
      level_id: 0,
      profit_gain_plus: 0,
      duration: 0,
      min_friend: 0,
      icon: "",
    }
  ) => {
    setSelectedCard(card);
    setOpen(true);
  };

  const handleDelete = async (id) => {
    try {
      await request(`v1/cards/delete/${id}`, "DELETE", null, setLoading);
      fetchCards();
      showNotification("success", "check", "Success", "Card deleted successfully!");
    } catch (error) {
      console.error("Error deleting card:", error);
      showNotification("error", "warning", "Error", "Failed to delete the card.");
    }
  };

  const handleInputChange = (e) => {
    setSelectedCard({ ...selectedCard, [e.target.name]: e.target.value });
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleSubmit = async () => {
    const formData = new FormData();
    if (selectedCard.name) formData.append("name", selectedCard.name);
    if (selectedCard.description) formData.append("text_description", selectedCard.description);
    formData.append("resource_needed", selectedCard.resource_needed || 0);
    formData.append("level_id", selectedCard.level_id || 0);
    formData.append("profit_gain_plus", selectedCard.profit_gain_plus || 0);
    formData.append("duration", Number(selectedCard.duration) || 0);
    formData.append("min_friend", Number(selectedCard.min_friend) || 0);

    if (selectedFile) {
      formData.append("icon", selectedFile);
    }

    try {
      const url = `v1/resource/update-card?cardId=${selectedCard.id}`;

      const res = await request(url, "POST", formData, setLoading);

      if (res.data.status === "success") {
        showNotification("success", "check", "Success", "Card updated successfully!");
      } else {
        showNotification("error", "warning", "Error", res.data.message);
      }

      setOpen(false);
      fetchCards();
    } catch (error) {
      console.error("Error submitting card:", error);
      showNotification("error", "warning", "Error", "Failed to submit the card.");
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Card>
          <MDBox
            mx={2}
            mt={-3}
            py={2}
            px={2}
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
            display="flex"
            justifyContent="space-between"
          >
            <MDTypography variant="h6" color="white">
              Manage Cards
            </MDTypography>
            {/* <MDButton variant="contained" color="primary" onClick={() => handleOpen()}>
              Add New Card
            </MDButton> */}
          </MDBox>
          <MDBox pt={3}>
            <DataTable
              table={{
                columns: columns,
                rows: cards?.map((card) => ({
                  ...card,
                  actions: "",
                })),
              }}
              isSorted={false}
              entriesPerPage={5}
              showTotalEntries={false}
              noEndBorder
            />
          </MDBox>
        </Card>
      </MDBox>
      <Footer />
      {/* Dialog for Create and Edit Card */}
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Edit Card</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            name="name"
            label="Card Name"
            type="text"
            fullWidth
            value={selectedCard.name}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="description"
            label="Description"
            type="text"
            fullWidth
            value={selectedCard.description}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="resource_needed"
            label="Resource Needed"
            type="number"
            fullWidth
            value={selectedCard.resource_needed}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="level_id"
            label="Level ID"
            type="number"
            fullWidth
            value={selectedCard.level_id}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="profit_gain_plus"
            label="Profit Gain Plus"
            type="number"
            fullWidth
            value={selectedCard.profit_gain_plus}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="duration"
            label="Duration"
            type="number"
            fullWidth
            value={selectedCard.duration}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="min_friend"
            label="Minimum Friend"
            type="number"
            fullWidth
            value={selectedCard.min_friend}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="icon"
            label="Icon"
            type="file"
            fullWidth
            onChange={handleFileChange}
          />
        </DialogContent>
        <DialogActions>
          <MDButton onClick={() => setOpen(false)} color="error">
            Cancel
          </MDButton>
          <MDButton onClick={handleSubmit} color="success">
            Update
          </MDButton>
        </DialogActions>
      </Dialog>
      {renderNotification}
    </DashboardLayout>
  );
}

CardManagement.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      description: PropTypes.string,
      resource_needed: PropTypes.number,
      level_id: PropTypes.number,
      profit_gain_plus: PropTypes.number,
      duration: PropTypes.number,
      min_friend: PropTypes.number,
      icon: PropTypes.string,
    }).isRequired,
  }),
};

export default CardManagement;
