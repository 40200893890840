import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// orbitex Dashboard React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

import useNotification from "services/NotificationService";

// orbitex Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import PropTypes from "prop-types";
import { useLoading } from "context/LoadingContext";
import { request } from "utils/utils";
import CardDialog from "components/CardDialog";

function ResourceView() {
  const { showNotification, renderNotification } = useNotification();
  const { resourceId } = useParams();
  const [resource, setResource] = useState(null);
  const [cards, setCards] = useState([]);
  const [columns, setColumns] = useState([]);
  const [open, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedCard, setSelectedCard] = useState({
    id: null,
    name: "",
    description: "",
    resource_needed: 0,
    level_id: 0,
    profit_gain_plus: 0,
    duration: 0,
    min_friend: 0,
    icon: "",
  });
  const { setLoading } = useLoading();

  const fetchResource = async () => {
    try {
      const response = await request(`v1/resource/${resourceId}`, "GET", null, setLoading);
      setResource(response.data);
      setCards(response.data.cards);
      prepareColumns();
    } catch (error) {
      console.error("Error fetching resource:", error);
      showNotification("error", "warning", "Error", "Failed to fetch resource data.");
    }
  };

  useEffect(() => {
    fetchResource();
  }, [resourceId]);

  const handleEdit = (card) => {
    setSelectedCard(card);
    setIsEdit(true);
    setOpen(true);
  };

  const prepareColumns = () => {
    setColumns([
      { Header: "ID", accessor: "id", align: "left" },
      { Header: "Name", accessor: "name", align: "left" },
      {
        Header: "Icon",
        accessor: "icon",
        align: "left",
        Cell: ({ row }) => <img width={50} height={50} src={row?.original?.icon} alt="icon" />,
      },
      { Header: "Description", accessor: "description", align: "left" },
      { Header: "Resource Needed", accessor: "resource_needed", align: "left" },
      { Header: "Level ID", accessor: "level_id", align: "left" },
      { Header: "Profit Gain Plus", accessor: "profit_gain_plus", align: "left" },
      {
        Header: "Actions",
        accessor: "actions",
        align: "center",
        Cell: ({ row }) => (
          <>
            <MDButton variant="contained" color="info" onClick={() => handleEdit(row.original)}>
              Edit
            </MDButton>
            <MDButton
              variant="contained"
              color="error"
              onClick={() => handleDelete(row?.original?.id)}
              style={{ marginLeft: 10 }}
            >
              Delete
            </MDButton>
          </>
        ),
      },
    ]);
  };

  const handleOpen = (
    card = {
      id: null,
      name: "",
      description: "",
      resource_needed: 0,
      level_id: 0,
      profit_gain_plus: 0,
      duration: 0,
      min_friend: 0,
      icon: "",
    }
  ) => {
    setSelectedCard(card);
    setIsEdit(!!card.id);
    setOpen(true);
  };

  const handleDelete = async (id) => {
    try {
      await request(`v1/resource/delete-card?cardId=${id}`, "DELETE", null, setLoading);
      fetchResource();
      showNotification("success", "check", "Success", "Card deleted successfully!");
    } catch (error) {
      console.error("Error deleting card:", error);
      showNotification("error", "warning", "Error", "Failed to delete the card.");
    }
  };

  const handleShowNotification = (status, message) => {
    showNotification(
      status === "success" ? "success" : "error",
      status === "success" ? "check" : "warning",
      status === "success" ? "Success" : "Error",
      message
    );
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        {resource && (
          <>
            <MDTypography variant="h4" mb={2}>
              Resource Details: {resource.name}
            </MDTypography>
            <MDBox mb={5}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  {resource.name && (
                    <MDTypography variant="body1">Name: {resource.name}</MDTypography>
                  )}
                  {resource.description_ && (
                    <MDTypography variant="body1">
                      Description: {resource.description_}
                    </MDTypography>
                  )}
                </Grid>

                <Grid item xs={12} md={6}>
                  {resource.decimal && (
                    <MDTypography variant="body1">Decimal: {resource.decimal}</MDTypography>
                  )}
                  {resource.position && (
                    <MDTypography variant="body1">Position: {resource.position}</MDTypography>
                  )}
                </Grid>

                <Grid item xs={12} md={6}>
                  {resource.img && (
                    <MDBox>
                      <MDTypography variant="body1">Image:</MDTypography>
                      <img
                        width={100}
                        src={resource.img}
                        alt="Resource Image"
                        style={{ marginTop: "8px" }}
                      />
                    </MDBox>
                  )}
                  {resource.icon && (
                    <MDBox mt={2}>
                      <MDTypography variant="body1">Icon:</MDTypography>
                      <img
                        width={50}
                        src={resource.icon}
                        alt="Resource Icon"
                        style={{ marginTop: "8px" }}
                      />
                    </MDBox>
                  )}
                </Grid>

                <Grid item xs={12} md={6}>
                  {resource.link && (
                    <MDTypography variant="body1">Link: {resource.link}</MDTypography>
                  )}
                  {resource.type && (
                    <MDTypography variant="body1">Type: {resource.type}</MDTypography>
                  )}
                  {resource.sound_claim && (
                    <MDTypography variant="body1">Sound Claim: {resource.sound_claim}</MDTypography>
                  )}
                </Grid>
              </Grid>
            </MDBox>

            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={2}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                justifyContent="space-between"
              >
                <MDTypography variant="h6" color="white">
                  Cards for Resource: {resource.name}
                </MDTypography>
                <MDButton variant="contained" color="primary" onClick={() => handleOpen()}>
                  Add New Card
                </MDButton>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{
                    columns: columns,
                    rows: cards?.map((card) => ({
                      ...card,
                      actions: "",
                    })),
                  }}
                  isSorted={false}
                  entriesPerPage={5}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </>
        )}
      </MDBox>
      <Footer />
      <CardDialog
        open={open}
        onClose={() => setOpen(false)}
        onSuccess={() => fetchResource()}
        card={selectedCard}
        isEdit={isEdit}
        showNotification={handleShowNotification} // ارسال showNotification به CardDialog
        resourceId={resourceId}
      />
      {renderNotification} {/* Render Notification */}
    </DashboardLayout>
  );
}

ResourceView.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      id: PropTypes.number,
      icon: PropTypes.string,
      name: PropTypes.string,
      description: PropTypes.string,
      resource_needed: PropTypes.number,
      level_id: PropTypes.number,
      profit_gain_plus: PropTypes.number,
      duration: PropTypes.number,
      min_friend: PropTypes.number,
    }),
  }),
};

export default ResourceView;
