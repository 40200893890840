import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import axios from "axios";

// orbitex Dashboard React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// فایل نوتیفیکیشن‌ها را ایمپورت کنید
import useNotification from "services/NotificationService";

// orbitex Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import { useNavigate } from "react-router-dom";
import { useLoading } from "context/LoadingContext";
import { request } from "utils/utils";

function Resource() {
  const { showNotification, renderNotification } = useNotification();
  const [resources, setResources] = useState([]);
  const [columns, setColumns] = useState([]);
  const [open, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedResource, setSelectedResource] = useState({
    id: null,
    name: "",
    icon: "",
    description_: "",
    decimal: 0,
    x: "",
    y: "",
    img: "",
    link: "",
    type: 0,
    sound_claim: "",
  });
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedIconFile, setSelectedIconFile] = useState(null);
  const { setLoading } = useLoading();

  const fetchResources = async () => {
    try {
      const response = await request(`v1/resource`, "GET", null, setLoading);
      setResources(response.data);
      prepareColumns();
    } catch (error) {
      console.error("Error fetching resources:", error);
    }
  };

  useEffect(() => {
    fetchResources();
  }, []);

  const navigate = useNavigate();

  const handleView = (id) => {
    navigate(`/resource/${id}/view`);
  };

  const prepareColumns = () => {
    setColumns([
      { Header: "ID", accessor: "id", align: "left" },
      { Header: "Name", accessor: "name", align: "left" },
      {
        Header: "Icon",
        accessor: "icon",
        align: "left",
        Cell: ({ row }) => <img width={50} height={50} src={row.original.icon} />,
      },
      { Header: "Description", accessor: "description_", align: "left" },
      {
        Header: "Image",
        accessor: "img",
        align: "left",
        Cell: ({ row }) => <img width={50} height={50} src={row.original.img} />,
      },
      { Header: "Link", accessor: "link", align: "left" },
      {
        Header: "Actions",
        accessor: "actions",
        align: "center",
        Cell: ({ row }) => (
          <>
            <MDButton variant="contained" color="info" onClick={() => handleOpen(row.original)}>
              Edit
            </MDButton>
            <MDButton
              variant="contained"
              color="error"
              onClick={() => handleDelete(row.original.id)}
              style={{ marginLeft: 10 }}
            >
              Delete
            </MDButton>
            <MDButton
              variant="contained"
              color="primary"
              onClick={() => handleView(row.original.id)} // دکمه view
              style={{ marginLeft: 10 }}
            >
              View
            </MDButton>
          </>
        ),
      },
    ]);
  };

  const handleDelete = async (id) => {
    try {
      await request(`v1/resource/${id}`, "DELETE", null, setLoading);
      fetchResources();
      showNotification("success", "check", "Success", "Resource deleted successfully!");
    } catch (error) {
      console.error("Error deleting resource:", error);
      showNotification("error", "warning", "Error", "Failed to delete the resource.");
    }
  };

  const handleOpen = (
    resource = {
      id: null,
      name: "",
      icon: "",
      description_: "",
      decimal: 0,
      x: "",
      y: "",
      img: "",
      link: "",
      type: 0,
      sound_claim: "",
    }
  ) => {
    setSelectedResource(resource);
    setIsEdit(!!resource.id);
    setOpen(true);
  };

  const handleInputChange = (e) => {
    setSelectedResource({ ...selectedResource, [e.target.name]: e.target.value });
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleIconFileChange = (e) => {
    setSelectedIconFile(e.target.files[0]);
  };

  const handleSubmit = async () => {
    const formData = new FormData();

    if (selectedResource.name) formData.append("name", selectedResource.name);
    if (selectedResource.description_)
      formData.append("description_", selectedResource.description_);
    if (selectedResource.decimal !== undefined)
      formData.append("decimal", selectedResource.decimal);

    const position = {
      x: selectedResource.x !== undefined ? selectedResource.x : null,
      y: selectedResource.y !== undefined ? selectedResource.y : null,
    };
    formData.append("position", JSON.stringify(position));

    if (selectedResource.link) formData.append("link", selectedResource.link);
    if (selectedResource.type !== undefined) formData.append("type", selectedResource.type);
    if (selectedResource.sound_claim) formData.append("sound_claim", selectedResource.sound_claim);

    if (selectedFile) {
      formData.append("img", selectedFile);
    }

    if (selectedIconFile) {
      formData.append("icon", selectedIconFile);
    }
    try {
      let res;
      if (isEdit) {
        res = await request(
          `v1/resource/edit?id=${selectedResource.id}`,
          "POST",
          formData,
          setLoading
        );
      } else {
        res = await request(`v1/resource`, "POST", formData, setLoading);
      }
      if (res.status == "success") showNotification("success", "check", "Success", res.message);
      else showNotification("error", "warning", "Error", res.message);
      console.log(res);
      setOpen(false);
      fetchResources();
    } catch (error) {
      console.error("Error submitting resource:", error);
      showNotification("error", "warning", "Error", "Failed to submit the resource.");
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <MDBox mb={3}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={2}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                  display="flex"
                  justifyContent="space-between"
                >
                  <MDTypography variant="h6" color="white">
                    Manage Resources
                  </MDTypography>
                  <MDButton variant="contained" color="primary" onClick={() => handleOpen()}>
                    Add New Resource
                  </MDButton>
                </MDBox>
                <MDBox pt={3}>
                  <DataTable
                    table={{
                      columns: columns,
                      rows: resources?.map((resource) => ({
                        ...resource,
                        actions: "",
                      })),
                    }}
                    isSorted={false}
                    entriesPerPage={5}
                    showTotalEntries={false}
                    noEndBorder
                  />
                </MDBox>
              </Card>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
      {/* Dialog for Create and Edit Resource */}
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>{isEdit ? "Edit Resource" : "Add New Resource"}</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            name="name"
            label="Resource Name"
            type="text"
            fullWidth
            required
            value={selectedResource.name}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="icon"
            label="Icon"
            type="file"
            fullWidth
            onChange={handleIconFileChange}
          />
          <TextField
            margin="dense"
            name="description_"
            label="Description"
            type="text"
            fullWidth
            value={selectedResource.description_}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="decimal"
            label="Decimal"
            type="number"
            fullWidth
            value={selectedResource.decimal}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="x"
            label="Position X"
            type="number"
            fullWidth
            value={selectedResource.x}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="y"
            label="Position Y"
            type="number"
            fullWidth
            value={selectedResource.y}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="link"
            label="Link"
            type="text"
            fullWidth
            value={selectedResource.link}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="type"
            label="Type"
            type="number"
            fullWidth
            value={selectedResource.type}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="sound_claim"
            label="Sound Claim"
            type="text"
            fullWidth
            value={selectedResource.sound_claim}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="img"
            label="Image"
            type="file"
            fullWidth
            onChange={handleFileChange}
          />
        </DialogContent>
        <DialogActions>
          <MDButton onClick={() => setOpen(false)} color="error">
            Cancel
          </MDButton>
          <MDButton onClick={handleSubmit} color="success">
            {isEdit ? "Update" : "Create"}
          </MDButton>
        </DialogActions>
      </Dialog>
      {renderNotification} {/* Render Notification */}
    </DashboardLayout>
  );
}

Resource.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      icon: PropTypes.string,
      description_: PropTypes.string,
      decimal: PropTypes.number,
      x: PropTypes.number,
      y: PropTypes.number,
      img: PropTypes.string,
      link: PropTypes.string,
      type: PropTypes.number,
      sound_claim: PropTypes.string,
    }),
  }),
};

export default Resource;
