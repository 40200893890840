import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import useNotification from "services/NotificationService";
import { useLoading } from "context/LoadingContext";
import { request } from "utils/utils";

function Game() {
  const { showNotification, renderNotification } = useNotification();
  const { setLoading } = useLoading();

  const [games, setGames] = useState([]);
  const [columns, setColumns] = useState([]);
  const [open, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedGame, setSelectedGame] = useState({ id: null, name: "", image: "", link: "" });
  const [selectedFile, setSelectedFile] = useState(null);

  const fetchGames = async () => {
    try {
      const response = await request(`v1/game`, "GET", null, setLoading);
      setGames(response);
      prepareColumns();
    } catch (error) {
      console.error("Error fetching games:", error);
      showNotification("error", "warning", "Error", "Failed to fetch games.");
    }
  };

  useEffect(() => {
    fetchGames();
  }, []);

  const prepareColumns = () => {
    setColumns([
      { Header: "ID", accessor: "id", align: "left" },
      { Header: "Name", accessor: "name", align: "left" },
      {
        Header: "Image",
        accessor: "image",
        align: "left",
        Cell: ({ row }) => <img width={50} height={50} src={row.original.image} alt="Game Image" />,
      },
      { Header: "Link", accessor: "link", align: "left" },
      {
        Header: "Actions",
        accessor: "actions",
        align: "center",
        Cell: ({ row }) => (
          <>
            <MDButton variant="contained" color="info" onClick={() => handleOpen(row.original)}>
              Edit
            </MDButton>
            <MDButton
              variant="contained"
              color="error"
              onClick={() => handleDelete(row.original.id)}
              style={{ marginLeft: 10 }}
            >
              Delete
            </MDButton>
          </>
        ),
      },
    ]);
  };

  const handleDelete = async (id) => {
    try {
      await request(`v1/game/${id}`, "DELETE", null, setLoading);
      fetchGames();
      showNotification("success", "check", "Success", "Game deleted successfully!");
    } catch (error) {
      console.error("Error deleting game:", error);
      showNotification("error", "warning", "Error", "Failed to delete the game.");
    }
  };

  const handleOpen = (game = { id: null, name: "", image: "", link: "" }) => {
    setSelectedGame(game);
    setIsEdit(!!game.id);
    setOpen(true);
  };

  const handleInputChange = (e) => {
    setSelectedGame({ ...selectedGame, [e.target.name]: e.target.value });
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append("name", selectedGame.name);
    formData.append("link", selectedGame.link);

    if (selectedFile) {
      formData.append("image", selectedFile);
    }
    try {
      const response = isEdit
        ? await request(`v1/game/edit?id=${selectedGame.id}`, "POST", formData, setLoading)
        : await request(`v1/game`, "POST", formData, setLoading);

      if (response.status === "error") {
        showNotification(
          "error",
          "warning",
          "Error",
          response.massage || "Failed to submit the game."
        );
      } else {
        showNotification(
          "success",
          "check",
          "Success",
          isEdit ? "Game updated successfully!" : "Game added successfully!"
        );
        setOpen(false);
        fetchGames();
      }
    } catch (error) {
      console.error("Error submitting game:", error);
      showNotification("error", "warning", "Error", "Failed to submit the game.");
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <MDBox mb={3}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={2}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                  display="flex"
                  justifyContent="space-between"
                >
                  <MDTypography variant="h6" color="white">
                    Manage Games
                  </MDTypography>
                  <MDButton variant="contained" color="primary" onClick={() => handleOpen()}>
                    Add New Game
                  </MDButton>
                </MDBox>
                <MDBox pt={3}>
                  <DataTable
                    table={{
                      columns: columns,
                      rows: games.map((game) => ({
                        ...game,
                        actions: "",
                      })),
                    }}
                    isSorted={false}
                    entriesPerPage={5}
                    showTotalEntries={false}
                    noEndBorder
                  />
                </MDBox>
              </Card>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />

      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>{isEdit ? "Edit Game" : "Add New Game"}</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            name="name"
            label="Game Name"
            type="text"
            fullWidth
            required
            value={selectedGame.name}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="image"
            label="Image"
            required
            type="file"
            fullWidth
            onChange={handleFileChange}
          />
          <TextField
            margin="dense"
            name="link"
            label="Game Link"
            type="text"
            required
            fullWidth
            value={selectedGame.link}
            onChange={handleInputChange}
          />
        </DialogContent>
        <DialogActions>
          <MDButton onClick={() => setOpen(false)} color="error">
            Cancel
          </MDButton>
          <MDButton onClick={handleSubmit} color="success">
            {isEdit ? "Update" : "Create"}
          </MDButton>
        </DialogActions>
      </Dialog>

      {renderNotification}
    </DashboardLayout>
  );
}

Game.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      image: PropTypes.string,
      link: PropTypes.string,
    }),
  }),
  Cell: PropTypes.func,
};

export default Game;
