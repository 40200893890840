import React, { useState } from "react";
import MDSnackbar from "components/MDSnackbar";

function useNotification() {
  const [notification, setNotification] = useState({
    open: false,
    color: "info",
    icon: "notifications",
    title: "Notification",
    content: "This is a notification.",
  });

  const showNotification = (color, icon, title, content) => {
    setNotification({
      open: true,
      color,
      icon,
      title,
      content,
    });

    setTimeout(() => {
      setNotification((prev) => ({ ...prev, open: false }));
    }, 3000);
  };

  const renderNotification = (
    <MDSnackbar
      color={notification.color}
      icon={notification.icon}
      title={notification.title}
      content={notification.content}
      open={notification.open}
      onClose={() => setNotification((prev) => ({ ...prev, open: false }))}
      close={() => setNotification((prev) => ({ ...prev, open: false }))}
    />
  );

  return { showNotification, renderNotification };
}

export default useNotification;
